<template>
  <div v-if="isNotice" class="p-wrap">
    <div class="p-header">
      <p-header border spacer title="红字信息表"></p-header>
      <p-select class="p-select" :model="selectForm" :limit="2" label-width="110px" @select="handleQuery" @reset="handleResetSelectForm">
        <el-form-item prop="redNoticeNo" label="红字信息表编号" slot="1">
          <el-input v-model.trim="selectForm.redNoticeNo" placeholder="请输入红字信息表编号" maxlength="64"></el-input>
        </el-form-item>
        <el-form-item prop="applyStatus" label="信息表状态" slot="1">
          <el-select v-model="selectForm.applyStatus" placeholder="请输入信息表状态">
            <el-option v-for="item in applyStatusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="buyerName" label="购方名称" slot="1">
          <el-input v-model.trim="selectForm.buyerName" placeholder="请输入购方名称" maxlength="200"></el-input>
        </el-form-item>
        <el-form-item prop="sellerName" label="销方名称" slot="2">
          <el-input v-model.trim="selectForm.sellerName" placeholder="请输入销方名称" maxlength="200"></el-input>
        </el-form-item>
        <el-form-item prop="invoiceCode" label="发票代码" slot="2">
          <el-input v-model.trim="selectForm.invoiceCode" placeholder="请输入发票代码" maxlength="12"></el-input>
        </el-form-item>
        <el-form-item prop="invoiceNo" label="发票号码" slot="2">
          <el-input v-model.trim="selectForm.invoiceNo" placeholder="请输入发票号码" maxlength="8"></el-input>
        </el-form-item>
      </p-select>
    </div>
    <div class="p-content">
      <div class="p-data">
        <p-header title="信息列表" style="padding: 16px 0">
          <el-button type="primary" @click="redInfoVisible = true">红字信息表填开</el-button>
        </p-header>
        <el-table border stripe v-loading="tableLoading" :header-cell-style="handleHeaderCellStyle" style="width: 100%" :data="tableData">
          <el-table-column type="index" label="序号" width="60"></el-table-column>
          <el-table-column prop="redNoticeNo" label="红字信息表编号" min-width="160">
            <template slot-scope="scope">
              <el-tooltip content="点击复制">
                <span class="red-notice-no" @click="handleCopy(scope.row.redNoticeNo)">{{ scope.row.redNoticeNo }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="invoiceCode" label="发票代码" width="140"></el-table-column>
          <el-table-column prop="invoiceNo" label="发票号码" width="140"></el-table-column>
          <el-table-column prop="sellerName" label="销方名称" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="buyerName" label="购方名称" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="sumAmount" label="价税合计" width="100" :formatter="fmtTableColumn"></el-table-column>
          <el-table-column prop="applyStatus" label="信息表状态" width="140" :formatter="fmtTableColumn"></el-table-column>
          <el-table-column prop="created" label="提交日期" width="140" :formatter="fmtTableColumn"></el-table-column>
          <el-table-column prop="billTime" label="填开日期" width="140" :formatter="fmtTableColumn"></el-table-column>
          <el-table-column prop="billMessage" label="信息表状态描述" min-width="200"></el-table-column>
          <el-table-column label="操作" fixed="right" width="140">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row)">详情</el-button>
              <el-button type="text" @click="handleUpdateStatus(scope.row)">查询</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页组件 -->
      <el-pagination class="p-page" :page-size="selectForm.size" :current-page="selectForm.current" :total="page.total" layout="total,sizes,prev,pager,next" :page-sizes="[15, 30, 50, 100]" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
    </div>
    <!-- 红字信息表弹窗 -->
    <el-dialog append-to-body :visible.sync="redInfoVisible" title="红字信息表填开" width="360px">
      <red-info-dialog v-if="redInfoVisible" @cancel="handleRedInfoCancel" @confirm="handleRedInfoConfirm"></red-info-dialog>
    </el-dialog>
    <!-- 已开发票信息 -->
    <el-dialog append-to-body :visible.sync="billingInvoiceVisible" title="发票信息" width="724px">
      <billing-invoice-dialog :info="billingInvoice" @cancel="billingInvoiceVisible = false" @confirm="handleConfirmSubmit"></billing-invoice-dialog>
    </el-dialog>
    <el-dialog append-to-body :visible.sync="noticeDetailVisible" width="1144px" :show-close="false">
      <billing-invoice-detail :info="billingInvoice"></billing-invoice-detail>
    </el-dialog>
  </div>
  <router-view v-else></router-view>
</template>

<script>
import PHeader from '@/components/pdt/PHeader';
import PSelect from '@/components/pdt/PSelect';
import RedInfoDialog from './components/RedInfoDialog';
import BillingInvoiceDialog from './components/BillingInvoiceDialog';
import BillingInvoiceDetail from './components/BillingInvoiceDetail';

import { getRedNoticeListByPage, getAuditStatus, getBillingInvoiceByCodeAndNo, getRedNoticeByBillingInvoiceId, submitApply, getBillingRedNoticeDetails } from '@/service/invoice-open';
import { selectInvoice } from '@/service/sal/invoice';
import { tableStyle, pagingMethods } from '@/util/mixins';

export default {
  name: 'SalHztzd',
  components: { PHeader, PSelect, RedInfoDialog, BillingInvoiceDialog, BillingInvoiceDetail },
  mixins: [tableStyle, pagingMethods],
  data() {
    return {
      isNotice: true, // 是否红字信息列表页
      tableLoading: false, // 数据加载loading
      redInfoVisible: false, // 红字信息单填开弹窗
      billingInvoiceVisible: false, // 已开发票信息弹窗
      noticeDetailVisible: false, // 红字信息单详情弹窗
      selectForm: {
        redNoticeNo: '',
        applyStatus: '',
        buyerName: '',
        sellerName: '',
        invoiceCode: '',
        invoiceNo: '',
        page: 1,
        size: 15
      },
      page: {
        total: 0,
        size: 15,
        current: 1
      },
      billingInvoice: {},
      applyStatusList: [], // 红字信息表状态
      tableData: []
    };
  },
  watch: {
    '$route.path': {
      handler() {
        this.isNotice = false;
        if (this.$route.path == '/open/issue/red-notice') {
          this.isNotice = true;
          console.log(this.isNotice,'isNotice')
          this.getDicts('bill_invoice_redNotice').then((res) => {
            //获取本地缓存
            let billingChannel = localStorage.getItem('BILLING_MODE');
            //A9处理
            if (billingChannel == 'HX-FWQ-JSP') {
              res.data = res.data.filter((item) => {
                if (item.value == 3 || item.value == 4) {
                  //只要申请成功或申请失败两张状态
                  return true;
                }
              });
            }

            this.applyStatusList = [{ label: '全部', value: '' }, ...res.data];
          });
          this.handleQuery();
        }
      },
      immediate: true
    }
  },
  methods: {
    handleSizeChange(value) {
      this.selectForm.size = value;
      this.selectForm.page = 1;
      this.handleQuery();
    },
    handleCurrentChange(value) {
      this.selectForm.page = value;
      this.handleQuery();
    },
    // 已开发票数据
    async handleQuery() {
      this.tableLoading = true;
      this.selectForm['startSource'] = 'MESEND'
      this.selectForm['systemSource'] = 'FQD'
      const { success, data } = await getRedNoticeListByPage(this.selectForm);
      this.tableLoading = false;
      if (success) {
        this.page = data;
        this.tableData = data.records;
      }
    },
    // 复制红字通知单编号
    async handleCopy(val) {
      if (!val) return;
      const clipboard = navigator.clipboard;
      if (!clipboard) {
        this.toast('当前浏览器不支持自动复制到粘贴板', 'warning');
        return;
      }
      await clipboard.writeText(val);
      this.toast('已复制到粘贴板', 'success');
    },
    // 详情显示
    async handleDetail(row) {
      const { success, data } = row.billingInvoiceId ? await selectInvoice(row.billingInvoiceId) : await getBillingRedNoticeDetails(row.id);
      this.noticeDetailVisible = false;
      if (success) {
        this.billingInvoice = { ...row, ...data };
        this.billingInvoice['redNoticeNo'] = row['redNoticeNo'];
        if (row.billingInvoiceId != 0) {
          this.billingInvoice['redSource'] = 'seller';
          this.billingInvoice['deductionType'] = '-1';
        }
        this.noticeDetailVisible = true;
      }
    },
    // 更新红字通知单申领状态
    async handleUpdateStatus(row) {
      const { success, message } = await getAuditStatus(row.id);
      if (success) this.toast('查询成功！', 'success', () => this.submitForm());
      else this.toast(message, 'error');
    },
    // 重制查询条件
    handleResetSelectForm() {
      this.page.size = 15;
      this.page.current = 1;
      this.handleQuery();
    },
    // 红字信息表填开 - 取消
    handleRedInfoCancel() {
      this.redInfoVisible = false;
    },
    // 红字信息表填开 - 确定
    handleRedInfoConfirm(info) {
      // 销方申请
      if (info.applicant == 'seller') {
        this.handleGetBillingInvoice(info);
        return;
      }
      // 购方申请
      this.handleRedInfoCancel();
      this.$router.replace({ path: '/open/issue/red-notice/form', query: info });
    },
    // 根据发票号码代码获取销售方发票
    async handleGetBillingInvoice(info) {
      info.invoiceTime = null;
      const { success, data } = await getBillingInvoiceByCodeAndNo(info);
      let dataInfo = data[0];
      if (success && dataInfo != null) {
        //要求 纸质专票 已开具 未被冲红、作废
        if (dataInfo.billingType != 'Zzzp' || dataInfo.invoiceStatus != 'YKJ' || dataInfo.billingFlag != 'Lp') {
          this.toast('此张发票不符合申请条件！', 'warning');
          return;
        }
        this.billingInvoice = dataInfo;
        // 设置发票的营业税标识
        this.billingInvoice.businessSign = info.businessSign;
        await this.handleGetRedNotice(dataInfo.id);
      } else {
        this.toast('未查到对应发票', 'error');
      }
    },
    // 根据已开发票id获取红字通知单
    async handleGetRedNotice(id) {
      const { success, data } = await getRedNoticeByBillingInvoiceId(id);
      // 未获取到红字通知单可申请
      if (success && data != null) return this.toast('此发票已申请过红字信息表', 'warning');
      this.handleRedInfoCancel();
      this.billingInvoiceVisible = true;
    },
    // 已开发票弹窗 点击确定触发 - 提交红字通知单
    async handleConfirmSubmit() {
      this.billingInvoice.submitDisabled = true;
      this.billingInvoice.redSource = 'seller';
      this.billingInvoice.deductionType = 0;
      const { success, message } = await submitApply(this.billingInvoice);
      if (success) {
        this.billingInvoiceVisible = false;
        this.billingInvoice = {};
        this.toast('提交申请成功！', 'success');
      } else {
        this.toast(message, 'warning');
      }
      this.handleResetSelectForm();
    },
    // 表格格式化
    fmtTableColumn(r, c) {
      switch (c['property']) {
        case 'sumAmount':
          return r.sumAmount ? '¥' + r.sumAmount : '';
        case 'applyStatus':
          return r.applyStatus ? this.getDictLabel(this.applyStatusList, r.applyStatus) : '未申请';
        case 'created':
          return this.$moment(r.created).format('YYYY/MM/DD');
        case 'billTime':
          return r.billTime ? this.$moment(r.billTime).format('YYYY/MM/DD') : '--';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.p-select {
  margin-top: 24px;

  .el-form-item {
    width: 300px;
  }
}

.p-content {
  padding: 0 20px 20px;

  .el-button--text {
    padding: 0;
  }
}

.red-notice-no {
  color: #3d94ff;
}

.red-notice-no:hover {
  cursor: pointer;
  font-weight: 500;
}
</style>
